<template>
<b-card>
    <b-form @submit="onSubmit">
        <b-row class="pb-2">
            <b-col
                md="6"
                sm="12"
            >
                <h5
                class="pt-1"
                >
                    Service Charge Type
                </h5>
                <b-form-select
                    :id="service.id"
                    v-model="service.value"
                    :name="service.db_name"
                    text-field="name"
                    value-field="id"
                    :options="service.options"
                    :placeholder="service.placeholder"
                    multiple
                />
            </b-col>
        </b-row>
        <b-button
            type="submit"
            variant="primary"
        >
            Submit
        </b-button>
        <GoBack />
    </b-form>
</b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormSelect,
    BButton,
    BForm,
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import GoBack from '../common/goBack.vue';
export default {
    components: {
        BFormSelect,
        BCard,
        BRow,
        BCol,
        GoBack,
        BButton,
        BForm,
    },
    data() {
        return {
            service: {},
            serviceId: {},
            url: '',
            id: 0,
        };
    },
    created() {
        if (this.$route.params.id) {
            this.id = this.$route.params.id;
            this.url = `/services/view/${this.id}`;
        } else {
            this.url = `/services/createView`;
        }
        this.getData();
    },
    methods: {
        async onSubmit(event) {
            event.preventDefault();
            let request = {};
            const formdata = new FormData();
            formdata.append('service_charge_type_id', this.service.value);
            request = formdata;
            let url = '';
            if (this.id) {
                url = `/services/update/${this.id}`;
            } else {
                url = `/services/save`;
            }
            this.$helper.showLoading();
                this.$axios
                    .post(url, request)
                    .then(resp => {
                        this.$helper.hideLoading();
                        if (resp.data) {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Services Added',
                                    icon: 'CheckIcon',
                                    variant: 'Primary',
                                },
                            });
                        }
                    })
                    .catch(err => {
                        this.$helper.hideLoading();
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Oops! Something went wrong',
                                icon: 'AlertCircleIcon',
                                variant: 'danger',
                            },
                        });
                    });
        },
        getData() {
            this.$helper.showLoading();
            this.$axios
                .get(this.url)
                .then(res => {
                    this.$helper.hideLoading();
                    console.log(res.data);
                    this.service = res.data.fields.service_charge_type_id;
                })
                .catch(error => {
                    this.$helper.hideLoading();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                    console.error(error);
                });
        },
    },
};
</script>
